import React from 'react';
import { IndexView } from 'ui';

const IndexPage = (): JSX.Element => {
  return (
    <IndexView />
  )
};

export default IndexPage;
